import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { AdModel } from "@store/ads";
import { AdDetailsDetailsModel, adDetailsService } from "@store/ads/details";

import { Colors } from "@constants/colors.constant";
import dayjs from "dayjs";

import ConfirmModal from "@components/modal/Confirm.modal";
import ManageAdModal from "./ManageAd.modal";
import { RELOAD_ADS } from "@constants/events.constant";

interface AdMenuProps {
  ad: AdModel | AdDetailsDetailsModel;
  isInDetails?: boolean;
}

const AdMenu = (props: AdMenuProps) => {
  const { ad, isInDetails } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openEdit, setOpenEdit] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleDeleteAnnouncement = () => {
    return adDetailsService.deleteCustomAd(ad.id).subscribe({
      next: () => {
        enqueueSnackbar(t("ads.manageCustom.success.archived"), { variant: "success" });
        if (!isInDetails) window.dispatchEvent(new Event(RELOAD_ADS));
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <Box>
      <Box>
        <IconButton onMouseDown={handleOpenMenu} onClick={(e) => e.stopPropagation()} size="small" sx={{ alignSelf: "flex-start" }}>
          <MoreVertIcon htmlColor={Colors.black} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          aria-label="user-menu"
          onClick={(evt) => evt.stopPropagation()}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}>
          <MenuItem
            onClick={(evt) => {
              evt.stopPropagation();
              setOpenEdit(true);
              handleCloseMenu();
            }}>
            <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
              <img alt="delete" src="/images/icon_edit_fill_black.svg" />
            </Stack>
            {t("global.modify")}
          </MenuItem>
          <MenuItem
            onClick={(evt) => {
              evt.stopPropagation();
              setOpenDuplicate(true);
              handleCloseMenu();
            }}>
            <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
              <img alt="delete" src="/images/icon_duplicate.svg" />
            </Stack>
            {t("global.duplicate")}
          </MenuItem>
          {(!ad.archivedAt || dayjs(ad.archivedAt).isAfter(dayjs())) && (
            <MenuItem
              color="primary"
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenDeleteConfirmation(true);
                handleCloseMenu();
              }}>
              <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                <img alt="delete" src="/images/icon_delete.svg" />
              </Stack>
              {t("ads.manageCustom.close")}
            </MenuItem>
          )}
        </Menu>
      </Box>
      {openDeleteConfirmation && (
        <ConfirmModal
          modalTitle={t(`ads.manageCustom.deleteConfirmationTitle`)}
          confirmMsg={t(`ads.manageCustom.deleteConfirmationDescriptionArchived`)}
          handleClose={() => setOpenDeleteConfirmation(false)}
          handleConfirm={() => handleDeleteAnnouncement()}
        />
      )}
      {openEdit && <ManageAdModal isInDetails={isInDetails} handleClose={() => setOpenEdit(false)} adId={ad.id} />}
      {openDuplicate && (
        <ManageAdModal
          isInDetails={isInDetails}
          handleClose={() => setOpenDuplicate(false)}
          adId={ad.id}
          isDuplicate={true}
          selectedAffiliate={{ label: ad.affiliate.name, value: ad.affiliate.id }}
        />
      )}
    </Box>
  );
};

export default AdMenu;
