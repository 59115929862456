import { BoostStatus, BoostType } from "@store/boosts";
import { AdModel } from "@store/ads";
import { UserModel } from "@store/users";
import { AffiliateLightModel } from "@store/entities/afiliates";
import { Application, ApplicationsListEnum, ApplicationsListEnumFunctions } from "@store/applications/applications.model";

import I18nUtils from "@utils/i18n.utils";

export enum ActionType {
  BOOST_REQUEST = "BOOST_REQUEST",
  BOOST_PROCESS = "BOOST_PROCESS",

  DELETE_ANNOUNCEMENT_JOBBOARD = "DELETE_ANNOUNCEMENT_JOBBOARD",
  ADD_ANNOUNCEMENT_JOBBOARD = "ADD_ANNOUNCEMENT_JOBBOARD",

  NEW_APPLICATION = "NEW_APPLICATION",
  APPLICATION_MOVED = "APPLICATION_MOVED",
  APPLICATION_RENAMED = "APPLICATION_RENAMED",
  APPLICATION_MOVED_TO_CVTHEQUE = "APPLICATION_MOVED_TO_CVTHEQUE",

  UPDATE_CUSTOM_ANNOUNCEMENT = "UPDATE_CUSTOM_ANNOUNCEMENT",
  CREATE_CUSTOM_ANNOUNCEMENT = "CREATE_CUSTOM_ANNOUNCEMENT",
  DELETE_CUSTOM_ANNOUNCEMENT = "DELETE_CUSTOM_ANNOUNCEMENT",
}

export interface ActionData {
  /* SUBSCRIPTION_REQUEST + SUBSCRIPTION_ACCEPTED + SUBSCRIPTION_REJECTED */
  subscriptionName?: string;

  /* BOOST REQUEST + BOOST_PROCESS */
  boostType?: BoostType;
  boostState?: BoostStatus;

  /* ADD_ANNOUNCEMENT_JOBBOARD + DELETE_ANNOUNCEMENT_JOBBOARD */
  jobBoardName?: string;

  /* NEW_APPLICATION, APPLICATION_MOVED, APPLICATION_RENAMED */
  applicationName?: string;
  inCVTheque?: boolean;

  /* APPLICATION_MOVED */
  fromList?: ApplicationsListEnum;
  toList?: ApplicationsListEnum;

  /* APPLICATION_RENAMED */
  toName: string;

  announcementReference?: string;
  announcementName?: string;
  modifiedFields?: string;
}

export interface Action {
  id: string;
  type: ActionType;
  date: Date;
  user: UserModel;
  affiliate: AffiliateLightModel;
  data: ActionData;
  announcement: AdModel;
  application: Application;
}

export namespace Action {
  export const description = (action: Action) => {
    switch (action.type) {
      case ActionType.ADD_ANNOUNCEMENT_JOBBOARD:
        return I18nUtils.t("actions.addJobBoard", {
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
          jobBoard: action.data.jobBoardName ?? "-",
        });
      case ActionType.DELETE_ANNOUNCEMENT_JOBBOARD:
        return I18nUtils.t("actions.removeJobBoard", {
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
          jobBoard: action.data.jobBoardName ?? "-",
        });
      case ActionType.BOOST_REQUEST:
        if (action.data.boostState === BoostStatus.VALIDATED) {
          return I18nUtils.t("actions.boostRequestValidated", {
            boost: BoostType.selectTypesItems.find((t) => t.value === action.data.boostType)?.label,
            ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
            name: action.announcement?.name ?? action.data.announcementName ?? "-",
          });
        }

        return I18nUtils.t("actions.boostRequest", {
          boost: BoostType.selectTypesItems.find((t) => t.value === action.data.boostType)?.label,
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
          validator:
            action.data.boostState === BoostStatus.WAITING_FOR_VALIDATION
              ? I18nUtils.t("actions.waiting.aBuyer")
              : I18nUtils.t("actions.waiting.twinin"),
        });
      case ActionType.BOOST_PROCESS:
        switch (action.data.boostState) {
          case BoostStatus.REJECTED:
            return I18nUtils.t("actions.boostRejected", {
              boost: BoostType.selectTypesItems.find((t) => t.value === action.data.boostType)?.label,
              ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
              name: action.announcement?.name ?? action.data.announcementName ?? "-",
            });
          case BoostStatus.IN_PROGRESS:
            return I18nUtils.t("actions.boostProcessBuyer", {
              boost: BoostType.selectTypesItems.find((t) => t.value === action.data.boostType)?.label,
              ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
              name: action.announcement?.name ?? action.data.announcementName ?? "-",
            });
          case BoostStatus.VALIDATED:
            return I18nUtils.t("actions.boostProcessTwinin", {
              boost: BoostType.selectTypesItems.find((t) => t.value === action.data.boostType)?.label,
              ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
              name: action.announcement?.name ?? action.data.announcementName ?? "-",
            });
          case BoostStatus.FINISHED:
            return I18nUtils.t("actions.boostFinished", {
              boost: BoostType.selectTypesItems.find((t) => t.value === action.data.boostType)?.label,
              ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
              name: action.announcement?.name ?? action.data.announcementName ?? "-",
            });
          default:
            return "";
        }
      case ActionType.NEW_APPLICATION:
        return I18nUtils.t("actions.newApplication", {
          application: action.application?.name ?? action.data.applicationName ?? "-",
          status: ApplicationsListEnumFunctions.label(ApplicationsListEnum.TO_BE_PROCESSED),
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        });
      case ActionType.APPLICATION_MOVED:
        return I18nUtils.t("actions.applicationMoved", {
          application: action.application?.customName ?? action.application?.name ?? action.data.applicationName ?? "-",
          fromList: ApplicationsListEnumFunctions.label(action.data.fromList),
          toList: ApplicationsListEnumFunctions.label(action.data.toList),
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        });
      case ActionType.APPLICATION_RENAMED:
        return I18nUtils.t(`actions.${action.data.inCVTheque ? "applicationRenamed" : "applicationRenamedCVTheque"}`, {
          application: action.data.applicationName ?? "-",
          toName: action.data.toName ?? "-",
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        });
      case ActionType.APPLICATION_MOVED_TO_CVTHEQUE:
        return I18nUtils.t("actions.applicationMovedToCVTheque", {
          application: action.application?.customName ?? action.application?.name ?? action.data.applicationName ?? "-",
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        });
      case ActionType.UPDATE_CUSTOM_ANNOUNCEMENT:
        return `${I18nUtils.t("actions.updateCustomAnnouncement", {
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        })}${
          action.data.modifiedFields
            ? `<br>${I18nUtils.t("actions.modifiedFields", {
                fields: action.data.modifiedFields,
              })}`
            : ""
        }`;
      case ActionType.CREATE_CUSTOM_ANNOUNCEMENT:
        return I18nUtils.t("actions.createCustomAnnouncement", {
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        });
      case ActionType.DELETE_CUSTOM_ANNOUNCEMENT:
        return I18nUtils.t("actions.deleteCustomAnnouncement", {
          ref: action.announcement?.reference ?? action.data.announcementReference ?? "-",
          name: action.announcement?.name ?? action.data.announcementName ?? "-",
        });
      default:
        return "";
    }
  };

  export const icon = (actionType: ActionType) => `/images/lastactions/${actionType.toLowerCase()}.svg`;
}
