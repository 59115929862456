import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { alpha, IconButton, outlinedInputClasses, Stack, TextField, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { styled } from "@mui/material/styles";
import { getLocalizedDateInputFormat } from "@store/common/country.model";

const StyledRedDatePicker = styled(DatePicker)({
  backgroundColor: "transparent !important",
  borderRadius: 7,
  [`& .${outlinedInputClasses.root}`]: {
    backgroundColor: alpha(Colors.primary, 0.1),
    height: 35,
  },
  [`& .${outlinedInputClasses.inputAdornedStart}`]: {
    paddingLeft: "10px",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: Colors.primary,
    fontSize: 14,
    fontWeight: 500,
    padding: "auto 8px !important",
    "&::placeholder": {
      opacity: 1,
    },
  },
}) as typeof DatePicker;

interface DatePickerComponentProps {
  color?: "primary" | "secondary";
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  handleChange?: (date?: Date) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  title?: string;
  error?: string;
  value?: Date;
  clearButton?: boolean;
  minDate?: Date;
  disableHighlightToday?: boolean;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {
  const {
    color = "primary",
    disabled,
    disableFuture,
    disablePast,
    format,
    handleChange,
    placeholder,
    readOnly,
    required,
    title,
    error,
    value,
    clearButton = false,
    minDate,
    disableHighlightToday = false,
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <StyledRedDatePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={disabled}
      readOnly={readOnly}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value || null}
      minDate={minDate || new Date("1500-01-01")}
      disableHighlightToday={disableHighlightToday}
      inputFormat={format || getLocalizedDateInputFormat()}
      onChange={(date) => handleChange?.(date || undefined)}
      renderInput={(params) => (
        <Stack>
          {title && (
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {title} {required ? "*" : undefined}
            </Typography>
          )}
          <TextField
            {...params}
            color={color}
            disabled={disabled}
            onClick={!readOnly && !disabled ? () => setOpen(true) : undefined}
            focused={readOnly ? false : undefined}
            InputProps={{
              ...params.InputProps,
              startAdornment: <img alt="calendar" src="/images/calendar_primary.svg" style={{ paddingRight: "6px", height: 20, width: 20 }} />,
              endAdornment: (
                <>
                  {clearButton && (
                    <IconButton onClick={(event) => { 
                      event.stopPropagation(); // Prevents the date picker from opening
                      handleChange?.(undefined); 
                    }} style={{ border: "none", background: "transparent", cursor: "pointer" }}>
                      <img alt="clear" src="/images/icon_close_primary.svg" style={{ height: 10, width: 10, marginRight: "6px" }} />
                    </IconButton>
                  )}
                  <img alt="" style={{ transform: open ? "rotate(180deg)" : undefined }} src="/images/arrow_dropdownlist_primary.svg" />
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              placeholder: placeholder,
            }}
            error={!!error}
            helperText={error}
          />
        </Stack>
      )}
    />
  );
};

export default DatePickerComponent;
