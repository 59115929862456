import React, { useMemo, useState } from "react";
import { alpha, CardActionArea, Stack, styled, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AdModel } from "@store/ads";
import SelectBoostRequestTypeModal from "@screens/auth/common/boosts/boostRequest/SelectBoostRequestType.modal";

import { Colors } from "@constants/colors.constant";
import AdMenu from "@screens/auth/common/ads/components/Ad.menu";
import dayjs from "dayjs";

const AdCardStyled = styled(Stack)(
  (props) => `
  background-color: ${Colors.white};
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  position: relative;
  width: 100%;
  & > *:first-of-type:not([aria-label=options]) {
    align-items: flex-start;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: ${props["aria-selected"] ? `inset 0px 0px 0px 1px ${Colors.primary},` : ""} 0px 0px 20px rgba(34,34,64, 0.05);
    height: 100%;
    justify-content: space-between;
    padding: 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
    &.Mui-disabled {
      opacity: 0.5;
    }
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`
);

const BoostMeButton = styled(Typography)(`
  color: ${Colors.primary};
  cursor: pointer;
  text-decoration: underline;
  padding: 5px 10px;
  margin-right: -5px !important;
`);

interface AdCardProps {
  ad: AdModel;
  disabled?: boolean;
  handleClick?: () => void;
  selected?: boolean;
  hideCandidatesNb?: boolean;
}

const AdCard = (props: AdCardProps) => {
  const { ad, disabled, handleClick, selected, hideCandidatesNb } = props;

  const { t } = useTranslation();

  const [openBoostRequest, setOpenBoostRequest] = useState(false);

  const onBoostMeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenBoostRequest(true);
  };

  const showCustomAdMenuButton = ad.isCustom;

  const deletionDate = useMemo(() => {
    if (!ad || !ad?.lastSeen || ad.isCustom) return undefined;

    return dayjs(ad.lastSeen).add(7, "d");
  }, [ad]);

  return (
    <AdCardStyled aria-selected={selected}>
      <Stack component={!handleClick ? Stack : CardActionArea} onClick={handleClick} disabled={disabled}>
        <Stack direction="row" alignSelf="flex-end" spacing={1} alignItems="center" height="22px" mr={showCustomAdMenuButton ? "24px" : undefined}>
          {!!deletionDate && (
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign={"center"}>
                  {t("ads.cardTooltip.isLastSeen", {
                    days: deletionDate.fromNow(),
                    date: deletionDate.toDate(),
                  })}
                </Typography>
              }
              arrow
              placement="top">
              <Stack height="22px" width="22px" bgcolor={Colors.lightError} borderRadius="4px" alignItems="center" justifyContent="center">
                <img alt="boost" src="/images/icon_clock.png" style={{ height: "12px", width: "12px" }} />
              </Stack>
            </Tooltip>
          )}
          {ad.managedByTwinin && (
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign={"center"}>
                  {t("ads.cardTooltip.managedByTwinin")}
                </Typography>
              }
              arrow
              placement="top">
              <Stack height="22px" width="22px" bgcolor={alpha(Colors.gold, 0.2)} borderRadius="4px" alignItems="center" justifyContent="center">
                <img alt="twininT" src="/images/icon_star_3d.svg" height="12px" />
              </Stack>
            </Tooltip>
          )}
          {(ad.applications?.aioApplications ?? 0) > 0 && (
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign={"center"}>
                  {t("ads.cardTooltip.hasApplications")}
                </Typography>
              }
              arrow
              placement="top">
              <Stack height="22px" width="22px" bgcolor={Colors.applicationsOrange} borderRadius="4px" alignItems="center" justifyContent="center">
                <img alt="boost" src="/images/logoAIO.svg" style={{ height: "25px", width: "25px" }} />
              </Stack>
            </Tooltip>
          )}
          {ad.isBoosted && (
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign={"center"}>
                  {t("ads.cardTooltip.isBoosted")}
                </Typography>
              }
              arrow
              placement="top">
              <Stack height="22px" width="22px" bgcolor={Colors.lightpurple} borderRadius="4px" alignItems="center" justifyContent="center">
                <img alt="boost" src="/images/icon_boost_rocket.svg" style={{ height: "12px", width: "12px" }} />
              </Stack>
            </Tooltip>
          )}
        </Stack>
        <Stack pb="20px" width="100%">
          <Typography fontSize={13} fontWeight="300" color={Colors.secondaryText}>
            {t(`global.reference`, { reference: ad.reference || "-" })}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={500}
            lineHeight={1.25}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineClamp: 2,
              WebkitLineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            }}>
            {ad.name}
          </Typography>
          {!!ad.affiliate && <Typography fontWeight={300}>{ad.affiliate.name}</Typography>}
          <Stack spacing={1} alignItems="center" direction="row">
            <img alt="location" src="/images/location.svg" />
            <Typography color={Colors.secondaryText} fontWeight={500} pt="1px" lineHeight={1.25}>
              {!!ad?.department && !!ad?.location && `${ad.department} - ${ad.location}`}
              {!!ad?.department && !ad?.location && ad.department}
              {!ad?.department && !!ad?.location && ad.location}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          {!hideCandidatesNb && (
            <Typography fontSize={14} fontWeight="300" color={"rgb(123, 123, 217)"}>
              {t(`global.candidates`, { count: (ad.applications?.aioApplications ?? 0) + (ad.applications?.powerBiApplications ?? 0) })}
            </Typography>
          )}
          {ad.inDistress && !ad.isBoosted && (!ad.archivedAt || dayjs(ad.archivedAt).isAfter(dayjs())) && (
            <BoostMeButton onMouseDown={onBoostMeClick} fontSize="12px" fontWeight="500">
              {t("ads.boostMe")}
            </BoostMeButton>
          )}
        </Stack>
      </Stack>
      {showCustomAdMenuButton && (
        <Stack aria-label="options" direction="row" alignItems="center" spacing="4px" position="absolute" right="10px" top="16px">
          <AdMenu ad={ad} />
        </Stack>
      )}
      {openBoostRequest && <SelectBoostRequestTypeModal adIdFromProps={ad.id} handleClose={() => setOpenBoostRequest(false)} />}
    </AdCardStyled>
  );
};

export default AdCard;
