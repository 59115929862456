import { catchError, from, map, mergeMap, Observable, of, switchMap, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import { MentionItem } from "react-mentions";

import { adDetailsStore } from "@store/ads/details/adDetails.store";
import {
  AdApplicationsStats,
  AdDetailsApplicationsList,
  AdDetailsDetailsModel,
  ManageAdDetailsModel,
  PotentialProfile,
} from "@store/ads/details/adDetails.model";
import {
  getAdDetailsApplicationsDataSource,
  getAdDetailsCommentsDataSource,
  getAdDetailsDetailsDataSource,
  getAdDetailsPotentialProfilesDataSource,
  getAdDetailsSlogansDataSource,
  getAdDetailsStatsDataSource,
  getAdDetailsTagsDataSource,
} from "@store/ads/details/adDetails.requests";
import { adsQuery, adsService, AdStats } from "@store/ads";
import { Comment } from "@store/common/comments.model";
import { filesService } from "@store/files";
import dayjs from "dayjs";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { sessionService } from "@store/session";
import { emitOnce } from "@ngneat/elf";
import { FeatureCreditsTypeEnum } from "@store/subscriptions";
import { AiApplicationEvaluationResponse, Application, ApplicationDetails, ApplicationsListEnum, SendCandidateEmail } from "@store/applications";

export class AdDetailsService {
  store = adDetailsStore;

  resetStore = () => this.store.reset();

  setDetails = (details: AdDetailsDetailsModel) =>
    this.store.update((state) => ({
      ...state,
      details,
    }));

  getAdDetails = (adId: string | number): Observable<AdDetailsDetailsModel> => {
    return from(APIAxios(APIRoutes.GETAdDetails(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update(
          (state) => ({
            ...state,
            details,
          }),
          getAdDetailsDetailsDataSource.setSuccess()
        );
      }),
      getAdDetailsDetailsDataSource.trackRequestStatus()
    );
  };

  getAdStats = (adId: string | number): Observable<AdStats> => {
    return from(APIAxios(APIRoutes.GETAdStats(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdStats>) => {
        return response.data;
      }),
      tap((stats) => {
        this.store.update(
          (state) => ({
            ...state,
            stats,
          }),
          getAdDetailsStatsDataSource.setSuccess()
        );
      }),
      getAdDetailsStatsDataSource.trackRequestStatus()
    );
  };

  getAdApplicationsStats = (adId: string | number, year: number): Observable<AdApplicationsStats> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdApplicationsStats(adId),
        params: { year },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdApplicationsStats>) => {
        return response.data;
      })
    );
  };

  getAdApplications = (adId: string | number, search?: string): Observable<AdDetailsApplicationsList[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdApplications(adId),
        params: { search },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdDetailsApplicationsList[]>) => {
        return response.data;
      }),
      tap((applications) => {
        this.store.update(
          (state) => ({
            ...state,
            applications,
          }),
          getAdDetailsApplicationsDataSource.setSuccess()
        );
      }),
      getAdDetailsApplicationsDataSource.trackRequestStatus()
    );
  };

  uploadAdApplications = (
    adId: string | number,
    list: ApplicationsListEnum,
    cvs: File[],
    jobboardId?: string
  ): Observable<AdDetailsApplicationsList[]> => {
    const formData = new FormData();

    if (jobboardId) formData.append("jobboardId", jobboardId);
    cvs.forEach((cv) => {
      formData.append("cv[]", cv);
    });

    return from(
      APIAxios({
        ...APIRoutes.POSTAddAdApplication(adId),
        data: formData,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        if ((err.response as any)?.data?.message?.[0]?.includes("Maximum file size is")) throw new SnackError("FILE_TOO_LARGE", "error");
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getAdDetails(adId)),
      switchMap(() => this.getAdApplications(adId))
    );
  };

  getAdApplicationDetails = (applicationId: string | number): Observable<ApplicationDetails> => {
    return from(APIAxios(APIRoutes.GETAdApplicationDetails(applicationId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ApplicationDetails>) => {
        return response.data;
      })
    );
  };

  editAdApplicationCategory = (applicationId: string | number, newCategory: ApplicationsListEnum): Observable<Application> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHAdApplication(applicationId),
        data: { list: newCategory },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Application>) => {
        return response.data;
      }),
      tap((adApplication) => {
        this.store.update(
          (state) => ({
            ...state,
            applications: state.applications?.map((category) => ({
              ...category,
              applications:
                newCategory === category.name
                  ? !category.applications.some((x) => x.id === applicationId)
                    ? [...category.applications, adApplication]
                    : category.applications
                  : category.applications.filter((a) => applicationId !== a.id),
            })),
          }),
          getAdDetailsApplicationsDataSource.setSuccess()
        );
      }),
      getAdDetailsApplicationsDataSource.trackRequestStatus()
    );
  };

  editCustomName = (adId: string, applicationId: string | number, customName: string): Observable<ApplicationDetails> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHApplicationCustomName(applicationId),
        data: { customName },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ApplicationDetails>) => {
        return response.data;
      }),
      switchMap(() => this.getAdApplications(adId)),
      switchMap(() => this.getAdApplicationDetails(applicationId))
    );
  };

  adApplicationComment = (
    applicationId: number | string,
    content: string,
    mentions: MentionItem[] = [],
    adId?: string | number
  ): Observable<ApplicationDetails> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAddApplicationComment(applicationId),
        data: { content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      switchMap(() => {
        if (adId !== undefined) {
          return this.getAdApplications(adId);
        } else {
          return of(null);
        }
      }),
      switchMap(() => this.getAdApplicationDetails(applicationId))
    );
  };

  downloadCV = (applicationId: string | number): Observable<Blob> => {
    return from(APIAxios(APIRoutes.GETDownloadCV(applicationId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  downloadCVOnly = (adId: string | number, applicationId: string | number): Observable<ApplicationDetails> => {
    return this.downloadCV(applicationId).pipe(
      switchMap((file) => this.getAdApplications(adId).pipe(map(() => file))),
      switchMap((file) =>
        this.getAdApplicationDetails(applicationId).pipe(tap((details) => filesService.downloadDocument(file, details.name + ".pdf")))
      )
    );
  };

  downloadCVsHasZip = (adId: string, applicationIds: string[], adName: string): Observable<Blob> => {
    return from(APIAxios({ ...APIRoutes.GETDownloadCVsHasZip(), params: { applicationIds } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      }),
      switchMap((file) => this.getAdApplications(adId).pipe(map(() => file))),
      tap((file) => filesService.downloadDocument(file, `CVs - ${adName}.zip`))
    );
  };

  downloadCVWithPreview = (adId: string | number, applicationId: string | number): Observable<ApplicationDetails> => {
    return this.downloadCV(applicationId).pipe(
      switchMap((file) => this.getAdApplications(adId).pipe(map(() => file))),
      switchMap((file) => this.getAdApplicationDetails(applicationId).pipe(tap(() => filesService.previewPDF(file))))
    );
  };

  moveToCVTheque = (applicationId: string, adId: string, removeCredits?: boolean): Observable<AdDetailsApplicationsList[]> => {
    return from(APIAxios({ ...APIRoutes.PATCHMoveApplicationsToCVTheque(), data: { applicationId } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Application[]>) => {
        return response.data;
      }),
      tap(() => {
        if (removeCredits) {
          sessionService.subtractCredit(FeatureCreditsTypeEnum.APPLICATIONS);
        }
      }),
      switchMap(() => this.getAdApplications(adId))
    );
  };

  sendEmailToCandidate = (applicationId: string, data: SendCandidateEmail, adId?: string): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTSendEmailToCandidate(applicationId),
        data: { type: data.type, email: data.email, text: data.text },
      })
    ).pipe(
      map((response: AxiosResponse) => {
        return response.data;
      }),
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => {
        if (adId) {
          this.getAdApplications(adId).subscribe();
        }
      })
    );
  };

  deleteCvs = (applicationIds: string[]): Observable<Application[]> => {
    return from(APIAxios({ ...APIRoutes.DELETEApplications(), data: { applicationIds } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Application[]>) => {
        return response.data;
      }),
      tap(() => {
        this.store.update(
          (state) => ({
            ...state,
            applications: state.applications?.map((category) => ({
              ...category,
              applications: category.applications.filter((a) => !applicationIds.includes(a.id)),
            })),
          }),
          getAdDetailsApplicationsDataSource.setSuccess()
        );
      })
    );
  };

  aiEvaluateApplication = (applicationId: string): Observable<AiApplicationEvaluationResponse> => {
    return from(APIAxios(APIRoutes.GETAiApplicationEvaluation(applicationId))).pipe(
      map((response: AxiosResponse<AiApplicationEvaluationResponse>) => {
        return response.data;
      }),
      catchError((err: AxiosError) => {
        return of({ error: true, erroredApplicationId: applicationId });
      })
    );
  };

  aiEvaluateApplications = (applicationIds: string[]): Observable<AiApplicationEvaluationResponse> => {
    return from(applicationIds).pipe(
      mergeMap((applicationId) => this.aiEvaluateApplication(applicationId)),
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  getAdComments = (adId: string | number): Observable<Comment[]> => {
    return from(APIAxios(APIRoutes.GETAdComments(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment[]>) => {
        return response.data;
      }),
      tap((comments) => {
        this.store.update(
          (state) => ({
            ...state,
            comments,
          }),
          getAdDetailsCommentsDataSource.setSuccess()
        );
      }),
      getAdDetailsCommentsDataSource.trackRequestStatus()
    );
  };

  getAdHistory = (adId: string | number, page: number = 1, take: number = 24): Observable<PaginatedData<any[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdHistory(adId),
        params: { page, take },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<any[]>>) => {
        return response.data;
      }),
      tap((actions) => {
        this.store.update((state) => ({
          ...state,
          actions: state.actions
            ? [...state.actions, ...actions.data.filter((action) => !state.actions?.some((a) => a.id === action.id))]
            : actions.data,
          actionsPaginatedMeta: actions.meta,
        }));
      })
    );
  };

  updateAd = (adId: string | number, ad: ManageAdDetailsModel): Observable<AdDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAd(adId),
        data: {
          slogan: ad.slogan || null,
          jobBoards: ad.jobBoards?.map((j) => j.value),
          criterias: ad.criterias || [],
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update((state) => ({
          ...state,
          details,
        }));
      })
    );
  };

  addComment = (adId: number | string, content: string, mentions: MentionItem[] = []): Observable<Comment> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAddAdComment(adId),
        data: { content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      tap((comment) => {
        this.store.update((state) => ({
          ...state,
          comments: [comment, ...(state.comments || [])],
        }));
      })
    );
  };

  updateComment = (adId: number | string, commentId: string, content: string, mentions: MentionItem[] = []): Observable<Comment> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAddComment(adId),
        data: { commentId, content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      tap((updatedComment) => {
        this.store.update((state) => {
          const updatedComments = state.comments?.map((comment) => (comment.id === updatedComment.id ? updatedComment : comment));
          return {
            ...state,
            comments: updatedComments ?? [],
          };
        });
      })
    );
  };

  getAdSlogans = (adId: string | number): Observable<string[]> => {
    return from(APIAxios(APIRoutes.GETAdSlogans(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string[]>) => {
        return response.data;
      }),
      tap((slogans) => {
        this.store.update(
          (state) => ({
            ...state,
            slogans,
          }),
          getAdDetailsSlogansDataSource.setSuccess()
        );
      }),
      getAdDetailsSlogansDataSource.trackRequestStatus()
    );
  };

  getAdTags = (adId: string | number): Observable<string[]> => {
    return from(APIAxios(APIRoutes.GETAdTags(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string[]>) => {
        return response.data;
      }),
      tap((tags) => {
        this.store.update(
          (state) => ({
            ...state,
            tags,
          }),
          getAdDetailsTagsDataSource.setSuccess()
        );
      }),
      getAdDetailsTagsDataSource.trackRequestStatus()
    );
  };

  getPotentialProfiles = (adId: string | number): Observable<PotentialProfile[]> => {
    return from(APIAxios(APIRoutes.GETPotentialProfiles(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PotentialProfile[]>) => {
        return response.data;
      }),
      tap((potentialProfiles) => {
        this.store.update(
          (state) => ({
            ...state,
            potentialProfiles,
          }),
          getAdDetailsPotentialProfilesDataSource.setSuccess()
        );
      }),
      getAdDetailsPotentialProfilesDataSource.trackRequestStatus()
    );
  };

  generatePotentialProfiles = (adId: string | number, removeCredit?: boolean): Observable<PotentialProfile[]> => {
    return from(APIAxios(APIRoutes.POSTGeneratePotentialProfiles(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PotentialProfile[]>) => {
        return response.data;
      }),
      tap((potentialProfiles) => {
        emitOnce(() => {
          if (removeCredit) {
            sessionService.subtractCredit(FeatureCreditsTypeEnum.POTENTIAL_PROFILES);
          }

          this.store.update(
            (state) => ({
              ...state,
              potentialProfiles,
            }),
            getAdDetailsPotentialProfilesDataSource.setSuccess()
          );
        });
      }),
      getAdDetailsPotentialProfilesDataSource.trackRequestStatus()
    );
  };

  associateAutomatically = (): Observable<number> => {
    return from(APIAxios(APIRoutes.POSTAssociateAutomatically())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<number>) => {
        return response.data;
      })
    );
  };

  createCustomAd = (data: ManageAdDetailsModel): Observable<AdDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTCreateCustomAd(),
        data: {
          affiliateId: data.affiliate?.value,
          location: data.location,
          name: data.name,
          department: data.department,
          region: data.region,
          description: data.description,
          companyInformation: data.companyInformation,
          profile: data.profile,
          advantages: data.advantages,
          minSalary: data.minSalary,
          maxSalary: data.maxSalary,
          contractType: data.contractType?.value,
          contractDuration: data.contractDuration,
          workingTime: data.workingTime,
          domain: data.domain?.value,
          status: data.status?.value,
          archivedAt: data.archivedAt,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  updateCustomAd = (adId: string | number, data: ManageAdDetailsModel): Observable<AdDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTUpdateCustomAd(adId),
        data: {
          description: data.description,
          advantages: data.advantages,
          minSalary: data.minSalary,
          maxSalary: data.maxSalary,
          archivedAt: data.archivedAt,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        if (!adsQuery.filters.isArchived && details.archivedAt && dayjs(details.archivedAt).isBefore(dayjs())) {
          adsService.removeOneEntity(adId.toString());
        }
        this.setDetails(details);
      })
    );
  };

  deleteCustomAd = (adId: string): Observable<AdDetailsDetailsModel> => {
    return from(APIAxios(APIRoutes.DELETECustomAd(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        if (!adsQuery.filters.isArchived) {
          adsService.removeOneEntity(adId.toString());
        }
        this.setDetails(details);
      })
    );
  };
}

export const adDetailsService = new AdDetailsService();
