import {
  alpha,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { adDetailsService } from "@store/ads/details";
import { Application, ApplicationsListEnum, ApplicationsListEnumFunctions } from "@store/applications";
import React, { ChangeEvent, CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmModal from "@components/modal/Confirm.modal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditCustomNameModal from "@components/modal/EditCustomName.modal";
import { NestedMenuItem } from "mui-nested-menu";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { FeatureCreditsTypeEnum, subscriptions, subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";
import CheckCreditsModal from "@components/modal/CheckCredits.modal";
import { filesService } from "@store/files";
import { AI_ICONS_PADDING, ITEM_GAP } from "@screens/auth/common/ads/components/AdsApplication.container";
import { DraggableProvided } from "react-beautiful-dnd";
import { getCallAnalysisColor, twilioAiCallsService, TwilioCallStatusEnum } from "@store/twilioAiCalls";
import CallConfirmationModal from "@screens/auth/common/ads/components/applicationDetails/CallConfirmation.modal";
import AIOButtonComponent from "@components/Button.component";
import SeeSubscriptionsModal from "@components/subscriptions/modal/SeeSubscriptions.modal";
import ModalComponent from "@components/modal/Modal.component";

const ApplicationCardStyled = styled(Card)(
  (props: { virtualized?: "true" | "false"; small?: "true" | "false" }) => `
  background-color: ${Colors.white};
  border-radius: 15px;
  box-shadow: ${props.virtualized === "true" ? "none" : "0px 0px 20px rgba(0, 0, 0, 0.05)"};
  opacity: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: ${props.small === "true" ? "pointer" : "default"};
  & .MuiCheckbox-root:not(.Mui-checked) {
    color: ${Colors.secondary};
  }
  & .MuiCheckbox-root .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
  & > #top-right-container {
    height: 35px;
    margin: auto;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  & > *[aria-label="first-child"] {
    align-items: flex-start;
    background-color: ${Colors.white};
    border-radius: 15px;
    padding: 22px 15px 23px 25px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  }

  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`
);

const AvatarStyled = styled(Stack)({
  width: "54px",
  minWidth: "54px",
  height: "54px",
  borderRadius: "50%",
  boxShadow: "0px 0px 2px #9393a9",
  border: `2px solid ${Colors.white}`,
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  "& > img": {
    width: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
  "&[aria-label='isIcon'] > img": {
    minHeight: "auto",
    maxHeight: "70%",
    objectFit: "contain",
  },
});

const StyledNestedMenuItem = styled(NestedMenuItem)`
  padding-left: 16px;
  & .MuiBox-root {
    align-items: center;
  }
  & .MuiTypography-root {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    line-height: 1.5;
  }
`;

const StlyedMenuItem = styled(MenuItem)`
  font-weight: 500 !important;
`;

const PADDING_RIGHT = "10px";

interface ApplicationCardProps {
  applyInfos: Application;
  downloadOnly?: boolean;
  handleClick?: () => void;
  small?: boolean;
  showDeleteBtn?: boolean;
  isDragged?: boolean;
  isInAiSortAnimation?: boolean;
  handleCheck?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  setCheckedApplications?: (value: React.SetStateAction<{ columnName: string; id: string }[]>) => void;
  checked?: boolean;
  applicationCategories?: ApplicationsListEnum[];
  categoryName?: string;
  handleEmailCandidate?: Dispatch<SetStateAction<Application | false>>;
  multipleDragNumber?: number;
  provided?: DraggableProvided;
  virtualized?: boolean;
  virtualizedStyle?: CSSProperties;
  setOpenCallChat?: Dispatch<SetStateAction<Application | null>>;
  applicationLoadingCalls?: string[];
}

const ApplicationCard = (props: ApplicationCardProps) => {
  const {
    applyInfos,
    downloadOnly,
    handleClick,
    small,
    isDragged,
    isInAiSortAnimation,
    handleCheck,
    setCheckedApplications,
    checked,
    applicationCategories,
    categoryName,
    handleEmailCandidate,
    multipleDragNumber,
    provided,
    virtualized,
    virtualizedStyle,
    setOpenCallChat,
    applicationLoadingCalls,
  } = props;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);

  const [checkUserCredits, setCheckUserCredits] = useState(false);

  const [phoneIcon, setPhoneIcon] = useState("");
  const [phoneTooltip, setPhoneTooltip] = useState("");
  const [loadingCall, setLoadingCall] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openCustomNameConfirmation, setOpenCustomNameConfirmation] = useState(false);
  const [openCallConfirmation, setOpenCallConfirmation] = useState(false);
  const [showFreemiumModal, setShowFreemiumModal] = useState(false);
  const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false);
  const [changedName, setChangedName] = useState<string>();

  const ad = useMemo(() => {
    return applyInfos.announcement;
  }, [applyInfos]);

  const color = useMemo(() => {
    if (!applyInfos.aiEvaluation) return "";

    if (applyInfos.aiEvaluation.mark < 4) {
      return Colors.error;
    } else if (applyInfos.aiEvaluation.mark < 7) {
      return Colors.orange;
    }
    return Colors.green;
  }, [applyInfos.aiEvaluation]);

  const handleMoveToCVTheque = (removeCredit?: boolean) => {
    if (!applyInfos || !adId) return;

    setLoading(true);
    adDetailsService
      .moveToCVTheque(applyInfos.id, adId, removeCredit)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          setCheckedApplications?.((prev) => prev.filter((item) => item.id !== applyInfos.id));
          enqueueSnackbar(t(`applications.moveToCVThequeSuccess`), { variant: "success" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleDeleteCV = () => {
    if (!applyInfos) return;
    setLoading(true);
    adDetailsService
      .deleteCvs([applyInfos.id])
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          setCheckedApplications?.((prev) => prev.filter((item) => item.id !== applyInfos.id));
          enqueueSnackbar(t(`applications.deleteSuccess`), { variant: "success" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleDownload = () => {
    if (!applyInfos || !adId) return;
    if (applyInfos.linkedinProfileURL) {
      return filesService.openUrlInNewTab(applyInfos.linkedinProfileURL);
    }
    setLoading(true);
    adDetailsService
      .downloadCVWithPreview(adId, applyInfos.id)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleMoveCv = (category: ApplicationsListEnum) => {
    if (!adId) return;
    adDetailsService.editAdApplicationCategory(applyInfos.id, category).subscribe({
      next: (a) => {
        if (category === ApplicationsListEnum.NOT_SELECTED) handleEmailCandidate?.(a);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handlePhoneClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (
      !applyInfos.twilioAiCall ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.NO_ANSWER ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.FAILED ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.CANCELED ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.BUSY
    ) {
      if (sessionQuery.user.role === UserRoleEnum.ADMIN || subscriptionsQuery.sessionUserSubscriptionOngoing || ad?.managedByTwinin) {
        setOpenCallConfirmation(true);
      } else {
        setShowFreemiumModal(true);
      }
    } else if (
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.COMPLETED ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.IN_PROGRESS ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.QUEUED ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.RINGING
    ) {
      setOpenCallChat?.(applyInfos);
    }
  };

  const handleConfirmCall = () => {
    setOpenCallConfirmation(false);
    setLoadingCall(true);
    twilioAiCallsService.makeAiCall(applyInfos).subscribe({
      next: (twilioAiCall) => {
        twilioAiCallsService.upsertEntities(twilioAiCall);
        twilioAiCallsService.handleSocketListeners([twilioAiCall]);
      },
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
        setLoadingCall(false);
      },
      complete: () => setLoadingCall(false),
    });
  };

  const getPhoneIcon = useCallback(() => {
    switch (applyInfos.twilioAiCall?.callStatus) {
      case TwilioCallStatusEnum.COMPLETED:
        return "/images/icon_phone_completed.svg";
      case TwilioCallStatusEnum.CANCELED:
        return "/images/icon_phone_refused.svg";
      case TwilioCallStatusEnum.QUEUED:
      case TwilioCallStatusEnum.RINGING:
      case TwilioCallStatusEnum.IN_PROGRESS:
        return "/images/icon_phone_ringing.svg";
      case TwilioCallStatusEnum.NO_ANSWER:
        return "/images/icon_phone_no_answer.svg";
      case TwilioCallStatusEnum.FAILED:
      case TwilioCallStatusEnum.BUSY:
        return "/images/icon_phone_refused.svg";
      default:
        if (loadingCall || applicationLoadingCalls?.includes(applyInfos.id)) return "/images/icon_phone_ringing.svg";
        if (!applyInfos.phoneNumber) return "/images/icon_phone_no_number.svg";
        if (!applyInfos.twilioAiCall) return "/images/icon_phone.svg";
        return "/images/icon_phone.svg";
    }
  }, [applicationLoadingCalls, applyInfos.id, applyInfos.phoneNumber, applyInfos.twilioAiCall, loadingCall]);

  useEffect(() => {
    setPhoneIcon(getPhoneIcon());
  }, [applyInfos.twilioAiCall?.callStatus, getPhoneIcon]);

  const getCallTooltip = useCallback(() => {
    if (!applyInfos.phoneNumber) return t("ads.details.applyTab.aiCalls.noPhoneNumber");
    if (!applyInfos.twilioAiCall) return t("ads.details.applyTab.aiCalls.makeCall");
    if (loadingCall || applicationLoadingCalls?.includes(applyInfos.id)) return t("ads.details.applyTab.aiCalls.aiAnalysisTitle");
    if (
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.CANCELED ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.FAILED ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.NO_ANSWER ||
      applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.BUSY
    )
      return t("ads.details.applyTab.aiCalls.retry");
    return t("ads.details.applyTab.aiCalls.consult");
  }, [applicationLoadingCalls, applyInfos.id, applyInfos.phoneNumber, applyInfos.twilioAiCall, loadingCall, t]);

  useEffect(() => {
    setPhoneTooltip(getCallTooltip());
  }, [getCallTooltip]);

  return (
    <div
      id={applyInfos?.id}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      style={{ ...virtualizedStyle, ...provided?.draggableProps.style, opacity: isInAiSortAnimation ? 0 : 1 }}>
      <Box
        sx={{
          marginBottom: small ? ITEM_GAP : undefined,
          pr: small ? PADDING_RIGHT : undefined,
          position: "relative",
        }}>
        {!!multipleDragNumber && multipleDragNumber > 1 && (
          <>
            {Array.from({ length: Math.min(multipleDragNumber - 1, 4) }).map((_, index) => (
              <ApplicationCardStyled
                key={index}
                sx={{
                  transform: `translate(${(index + 1) * 4}px, ${(index + 1) * 4}px)`,
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: `calc(100% - ${PADDING_RIGHT})`,
                  height: "100%",
                  zIndex: 0 - index,
                }}>
                <Stack
                  aria-label="first-child"
                  component={Stack}
                  direction="row"
                  width="100%"
                  sx={{ opacity: 0.2, border: "1px solid " + Colors.primary }}></Stack>
              </ApplicationCardStyled>
            ))}
          </>
        )}
        <ApplicationCardStyled virtualized={virtualized ? "true" : "false"} small={small ? "true" : "false"}>
          {!!multipleDragNumber && multipleDragNumber > 1 && (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                opacity: 0.4,
                position: "absolute",
                bottom: "10px",
                right: "10px",
                borderRadius: "5px",
                border: `1px solid ${alpha(Colors.primary, 0.5)}`,
                width: "30px",
                height: "30px",
              }}>
              <Typography fontSize={12}>{`+${multipleDragNumber - 1}`}</Typography>
            </Stack>
          )}
          <Stack
            aria-label="first-child"
            component={Stack}
            onClick={handleClick}
            direction="row"
            width="100%"
            pt={applyInfos.twilioAiCall || applyInfos.aiEvaluation ? `${AI_ICONS_PADDING}px !important` : undefined}
            sx={{ opacity: isDragged ? 0.2 : 1, border: isDragged ? "1px solid " + Colors.primary : "none", position: "relative" }}>
            <Stack direction="row" flex={1} justifyContent="space-between" alignItems="center" width="100%">
              <Stack width="90%">
                <Stack direction="row" spacing="5px" alignItems="center" mb="10px">
                  {small && !isDragged && !applyInfos.twilioAiCall && !applyInfos.aiEvaluation && (
                    <Checkbox
                      id={"item-checkbox" + applyInfos.id}
                      size="small"
                      onChange={handleCheck}
                      checked={checked}
                      onClick={(e) => e.stopPropagation()}
                    />
                  )}
                  <Typography
                    width={applyInfos.aiEvaluation ? "105px" : undefined}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontWeight={500}>
                    {changedName || applyInfos.customName || applyInfos.name}
                  </Typography>
                  {!small && applyInfos.emailAutoSentAt && (
                    <Tooltip title={t("ads.details.applyTab.emailAutoSent")}>
                      <img alt="emailAutoSent" src="/images/icon_email_sent.svg" style={{ height: 14 }} />
                    </Tooltip>
                  )}
                </Stack>
                {(applyInfos.candidate?.fullName || applyInfos.candidate?.city) && (
                  <Stack direction="row" alignItems="center" spacing="10px" mb="10px">
                    <AvatarStyled aria-label={!applyInfos.candidate?.picture ? "isIcon" : ""}>
                      <img alt="profile" src={applyInfos.candidate?.picture ?? "/images/icon_users_grey.svg"} />
                    </AvatarStyled>
                    <Stack spacing="4px">
                      {applyInfos.candidate?.fullName && (
                        <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" fontWeight={500}>
                          {applyInfos.candidate?.fullName}
                        </Typography>
                      )}
                      {applyInfos.candidate?.city && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <img alt="location" src="/images/location.svg" />
                          <Typography fontWeight={300} color={Colors.greyLight} lineHeight={1}>
                            {applyInfos.candidate?.city}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                )}
                {!!applyInfos.jobboard && (
                  <Stack direction="row">
                    <Typography lineHeight="1.25" fontWeight={300}>
                      Jobboard: {applyInfos.jobboard.name}
                    </Typography>
                  </Stack>
                )}
                {!!applyInfos.aioApplicationId && (
                  <Stack direction="row" alignItems="center" spacing="3px" ml="-4px">
                    <Box component="img" height="20px" alt="" src="/images/logo 2.svg" />
                    <Typography lineHeight="1.25" fontWeight={300}>
                      {t("applications.aio")}
                    </Typography>
                  </Stack>
                )}
                <Stack spacing={"5px"}>
                  <Typography fontWeight={300} color={Colors.grey}>
                    {t("global.date", { date: new Date(applyInfos.date) })}
                  </Typography>
                  {small && (
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {applyInfos.commentNumber ? (
                          <img alt="commentRed" src="/images/icon_comment_cv_red.svg" style={{ width: 13, height: 13 }} />
                        ) : (
                          <img alt="comment" src="/images/icon_comment_cv.svg" style={{ width: 13, height: 13 }} />
                        )}
                        <Typography color={applyInfos.commentNumber ? Colors.primary : undefined}>{applyInfos.commentNumber ?? 0}</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <img alt="eye" src="/images/icon_eye_cv.svg" style={{ width: 13, height: 13 }} />
                        <Typography>{applyInfos.viewNumber ?? 0}</Typography>
                      </Stack>
                      {!!applyInfos.inCVTheque && (
                        <Tooltip title={t("ads.details.applyTab.inCVTheque")}>
                          <Stack>
                            <img alt="inCVTheque" src="/images/cvtheque_black.svg" style={{ height: 15 }} />
                          </Stack>
                        </Tooltip>
                      )}
                      {applyInfos.emailAutoSentAt && (
                        <Tooltip title={t("ads.details.applyTab.emailAutoSent")}>
                          <Stack>
                            <img alt="emailAutoSent" src="/images/icon_email_sent.svg" style={{ height: 14 }} />
                          </Stack>
                        </Tooltip>
                      )}
                      {!!phoneIcon && categoryName !== ApplicationsListEnum.TO_BE_PROCESSED && (
                        <Tooltip
                          title={
                            <Typography fontSize={14} fontWeight={300}>
                              {phoneTooltip}
                            </Typography>
                          }>
                          {loadingCall || applicationLoadingCalls?.includes(applyInfos.id) ? (
                            <CircularProgress size={15} />
                          ) : !!applyInfos.phoneNumber ? (
                            <IconButton onClick={handlePhoneClick}>
                              <img src={phoneIcon} alt="phone" />
                            </IconButton>
                          ) : (
                            <Box padding={"5px"}>
                              <img src={phoneIcon} alt="phone" />
                            </Box>
                          )}
                        </Tooltip>
                      )}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
            {!small && (
              <Stack direction="row" alignSelf="center">
                {adId && !downloadOnly && (
                  <IconButton
                    disabled={loading}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      setOpenCustomNameConfirmation(true);
                    }}
                    sx={{ marginX: "10px" }}>
                    <img width="18px" alt="edit" src="/images/edit.svg" />
                  </IconButton>
                )}
                <IconButton
                  disabled={loading}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleDownload();
                  }}>
                  <img alt="download" src="/images/icon_download_file.svg" />
                </IconButton>
              </Stack>
            )}
          </Stack>
          {small && !isDragged && (
            <Stack
              direction="row"
              width={"100%"}
              justifyContent={applyInfos.twilioAiCall || applyInfos.aiEvaluation ? "space-between" : "flex-end"}
              alignItems="center"
              spacing="2px"
              id="top-right-container">
              {(applyInfos.twilioAiCall || applyInfos.aiEvaluation) && (
                <Box mr="auto" justifySelf={"flex-start"} pl={"28px"}>
                  <Checkbox size="small" onChange={handleCheck} checked={checked} onClick={(e) => e.stopPropagation()} />
                </Box>
              )}
              <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={"5px"}>
                {!!applyInfos.twilioAiCall?.callAnalysis && (
                  <Tooltip
                    disableHoverListener={!applyInfos.twilioAiCall.callAnalysis?.analysis}
                    title={
                      <Stack spacing={1}>
                        <Typography fontSize={14} fontWeight={500} textAlign="center">
                          {t("ads.details.applyTab.aiCalls.aiAnalysisTitle")}
                        </Typography>
                        <Typography fontSize={11} fontWeight={400} textAlign="center">
                          {applyInfos.twilioAiCall.callAnalysis?.analysis.split(". ").map((line, index, array) => (
                            <React.Fragment key={index}>
                              {line}
                              {index < array.length - 1 && (
                                <>
                                  <span>.</span>
                                  <br />
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </Typography>
                      </Stack>
                    }
                    arrow
                    placement="top">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing="6px"
                      py="5px"
                      px="7px"
                      borderRadius="7px"
                      bgcolor={alpha(getCallAnalysisColor(applyInfos.twilioAiCall.callAnalysis?.mark ?? 0), 0.2)}>
                      <Typography fontWeight={400} fontSize="11px" color={getCallAnalysisColor(applyInfos.twilioAiCall.callAnalysis?.mark ?? 0)}>
                        {applyInfos.twilioAiCall.callAnalysis?.mark ?? 0}
                      </Typography>
                      <img width="18px" src="/images/ia_robot_call.png" alt="robot" />
                    </Stack>
                  </Tooltip>
                )}
                {applyInfos.aiEvaluation && (
                  <Tooltip
                    title={
                      <Stack spacing={1}>
                        <Typography fontSize={14} fontWeight={500} textAlign="center">
                          {t("ads.details.applyTab.aiSorting.aiAnalysisTitle")}
                        </Typography>
                        <Typography fontSize={11} fontWeight={400} textAlign="center">
                          {applyInfos.aiEvaluation.analysis.split(". ").map((line, index, array) => (
                            <React.Fragment key={index}>
                              {line}
                              {index < array.length - 1 && (
                                <>
                                  <span>.</span>
                                  <br />
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </Typography>
                      </Stack>
                    }
                    arrow
                    placement="top">
                    <Stack direction="row" alignItems="center" spacing="6px" py="5px" px="7px" borderRadius="7px" bgcolor={alpha(color, 0.1)}>
                      <Typography fontWeight={400} fontSize="11px" color={color}>
                        {applyInfos.aiEvaluation.mark}
                      </Typography>
                      <img width="18px" src="/images/ia_robot.svg" alt="robot" />
                    </Stack>
                  </Tooltip>
                )}
                <IconButton onClick={handleOpenMenu} disabled={loading} size="small" sx={{ alignSelf: "flex-start" }}>
                  <MoreVertIcon htmlColor={Colors.black} />
                </IconButton>
              </Stack>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                aria-label="user-menu"
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}>
                {applicationCategories && (
                  <StyledNestedMenuItem
                    nonce={undefined}
                    aria-label="user-menu"
                    leftIcon={
                      <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                        <MultipleStopIcon />
                      </Stack>
                    }
                    label={t("ads.details.applyTab.moveTo")}
                    parentMenuOpen={openMenu}>
                    <MenuList aria-label="user-menu">
                      {applicationCategories
                        .filter((category) => category !== categoryName)
                        .map((category) => (
                          <StlyedMenuItem key={category} onClick={() => handleMoveCv(category)}>
                            {ApplicationsListEnumFunctions.label(category)}
                          </StlyedMenuItem>
                        ))}
                    </MenuList>
                  </StyledNestedMenuItem>
                )}
                <MenuItem
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleDownload();
                    handleCloseMenu();
                  }}>
                  <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                    <img alt="download" src="/images/icon_download_file_black.svg" />
                  </Stack>
                  {t("applications.downloadCV")}
                </MenuItem>
                <MenuItem
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setOpenCustomNameConfirmation(true);
                    handleCloseMenu();
                  }}>
                  <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                    <img alt="edit" src="/images/icon_edit_fill_black.svg" />
                  </Stack>
                  {t("applications.editName")}
                </MenuItem>
                {!applyInfos.inCVTheque && !applyInfos.linkedinProfileURL && (
                  <MenuItem
                    onClick={(evt) => {
                      evt.stopPropagation();
                      if (
                        sessionQuery.user.role === UserRoleEnum.ADMIN ||
                        subscriptionsQuery.sessionUserSubscriptionOngoing ||
                        applyInfos.announcement.managedByTwinin
                      ) {
                        handleMoveToCVTheque();
                      } else {
                        setCheckUserCredits(true);
                      }
                      handleCloseMenu();
                    }}>
                    <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                      <img alt="delete" src="/images/cvtheque_black.svg" />
                    </Stack>
                    {t("applications.moveToCVthèque")}
                  </MenuItem>
                )}
                <MenuItem
                  color="primary"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setOpenDeleteConfirmation(true);
                    handleCloseMenu();
                  }}>
                  <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                    <img alt="delete" src="/images/icon_delete.svg" />
                  </Stack>
                  {t("applications.deleteApplication")}
                </MenuItem>
              </Menu>
            </Stack>
          )}
        </ApplicationCardStyled>
      </Box>

      {openDeleteConfirmation && (
        <ConfirmModal
          modalTitle={t("applications.deleteApplication")}
          confirmMsg={t(`applications.${applyInfos.inCVTheque ? "removeApplicationDescription" : "deleteApplicationDescription"}`)}
          handleClose={() => setOpenDeleteConfirmation(false)}
          handleConfirm={() => handleDeleteCV()}
        />
      )}
      {openCustomNameConfirmation && (
        <EditCustomNameModal
          adId={adId}
          applyInfoId={applyInfos.id}
          handleCloseModal={() => setOpenCustomNameConfirmation(false)}
          fieldName={changedName || applyInfos.customName || applyInfos.name}
          handleChangeName={setChangedName}
        />
      )}
      {checkUserCredits && (
        <CheckCreditsModal
          handleCreditsValidated={() => {
            setCheckUserCredits(false);
            handleMoveToCVTheque(true);
          }}
          handleCloseCheck={() => setCheckUserCredits(false)}
          type={FeatureCreditsTypeEnum.APPLICATIONS}
        />
      )}
      {openCallConfirmation && (
        <CallConfirmationModal
          recontact={
            applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.NO_ANSWER ||
            applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.FAILED ||
            applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.CANCELED ||
            applyInfos.twilioAiCall?.callStatus === TwilioCallStatusEnum.BUSY
          }
          handleConfirmCall={handleConfirmCall}
          handleClose={() => setOpenCallConfirmation(false)}
        />
      )}
      {showFreemiumModal && (
        <>
          <ModalComponent
            handleClose={() => setShowFreemiumModal(false)}
            title={t(`ads.details.applyTab.aiCalls.makeCall`)}
            icon={<img width={60} alt="warning" src="/images/illus_warning.png" />}
            content={
              <Stack mt="20px" spacing={3} px="50px" pb="20px">
                <Typography textAlign="center" lineHeight={1.5}>
                  {t(`ads.details.applyTab.aiCalls.notForFreemium`)}
                </Typography>
              </Stack>
            }
            actions={
              <AIOButtonComponent
                title={t("subscriptions.seeSubscriptions")}
                variant="contained"
                ariaLabel="modal"
                onClick={() => setShowSubscriptionsModal(true)}
              />
            }
            maxWidth="sm"
          />
          {showSubscriptionsModal && (
            <SeeSubscriptionsModal
              subscriptions={subscriptions.filter((s) => s.type === SubscriptionTypeEnum.COMPANY || s.type === SubscriptionTypeEnum.PROFESSIONAL)}
              handleClose={() => setShowSubscriptionsModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ApplicationCard;
